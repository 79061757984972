@import '../../styles/_variables';

.seamstress {
    background: $white;
    min-height: 100vh;
    &__header {
        background: $gradient-black;
        width: 100%;
        height: 62px;
        display: flex;
        justify-content: space-between;
    }
    &__exit {
        padding: 17px;
        font-size: 20px;
        color: $gray-light;
        font-weight: 600;
        &:hover {
            color: $gray;
            cursor: pointer;
        }
        @media (max-width: 600px) {
            font-size: 16px;
        }
    }
    .salary {
        width: 100%;
        padding-top: 30px;
        display: flex;
        align-items: end;
        flex-direction: column;
        &__text {
            max-width: 350px;
            font-size: 19px;
            font-weight: 500;
            @media (max-width: 500px) {
                font-size: 16px;
            }
        }
    }
    .task {
        .ui.action.input > .button,
        .ui.action.input > .buttons > .button {
            border-radius: 0.28571429rem 0.28571429rem 0.28571429rem 0.28571429rem;
        }
        .ui.action.input:not([class*='left action']) > input {
            border-radius: 0 !important;
            border-right-color: transparent !important;
            border-left-color: transparent !important;
        }
        .element.style {
            border-radius: 0 0.28571429rem 0.28571429rem 0;
        }
        &__grid {
            padding-top: 15px;
        }
        &__button {
            font-size: 16px;
            width: 125px;
            height: 45px;
        }
        &__number {
            width: 50%;
            font-size: 17px;
            font-weight: 700;
        }
        &__text {
            background-color: #f6faff;
            span {
                color: $dark;
                font-size: 18px;
                font-weight: 600;
                @media (max-width: 500px) {
                    font-size: 16px;
                }
            }
        }
        &__headerTask{
            display: flex;
            align-items: center;
            justify-content: space-between;
            p{
                margin: 0;
            }
        }
        &__headerSubtitle{
            font-size: 16px !important;
        }
        &__block{
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        &__bodySea {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__cost {
            font-size: 15px;
            margin-right: 20px;
        }
        &__costMubile {
            display: none;
            @media (max-width: 850px) {
                display: block;
            }
        }
        &__buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 850px) {
                display: none;
            }
        }
        &__buttonsMobile {
            margin-top: 20px;
            display: none;
            @media (max-width: 850px) {
                display: flex;
            }
        }
        &__buttonOne {
            width: 190px;
        }
        &__buttonTwo {
            width: 140px;
        }
        &__input {
            height: 45px;
            max-width: 90px;
        }
        &__title {
            margin: 25px;
            span {
                color: $gray-dark;
                float: right;
                font-size: 18px;
                font-weight: 600;
            }
            p {
                color: $black;
                display: inline-block;
                font-size: 20px;
                font-weight: 500;
                @media (max-width: 1300px) {
                    font-size: 18px;
                }
                @media (max-width: 500px) {
                    font-size: 16px;
                }
            }
        }
    }
}
