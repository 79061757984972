@import '../../styles/_variables';

@media only screen and (min-width: 768px) {
    .ui.selection.dropdown .menu {
        max-height: 400px;
    }
}
@media only screen and (max-width: 767px) {
    .ui.selection.dropdown .menu {
        max-height: 250px;
    }
}
.authorization-personal {
    background: $white;
    min-height: 100vh;

    //                                                          Header

    .header {
        background: $gradient-black;
        min-width: 100%;
        height: 150px;
        &__body {
            padding-top: 40px;
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        &__title {
            font-size: 30px;
            color: $white;
            text-align: center;
            @media (max-width: 800px) {
                font-size: 24px;
            }
            @media (max-width: 600px) {
                font-size: 17px;
            }
        }
        &__nav {
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 35px 0 0 0;
            @media (max-width: 600px) {
                width: 70px;
            }
            span {
                font-size: 20px;
                color: $gray-light;
                font-weight: 600;
                @media (max-width: 600px) {
                    font-size: 14px;
                }
                &:hover {
                    color: $gray;
                    cursor: pointer;
                }
            }
        }
    }

    //                                                                Form
    .form {
        &__error {
            width: 100%;
            position: fixed;
            margin: 0 auto;
            top: 20px;
            z-index: 500;
            &-body {
                width: 450px;
                margin: 0 auto;
                background: $red;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                @media (max-width: 800px) {
                    width: 250px;
                }
                p {
                    margin: 15px;
                    color: white;
                    font-size: 18px;
                }
            }
        }
        &__body {
            width: 601px;
            margin: 0 auto;
            text-align: center;
            @media (max-width: 800px) {
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .selected.item {
            font-size: 25px;
            @media (max-width: 600px) {
                font-size: 20px;
            }
            @media (max-width: 500px) {
                font-size: 15px;
            }
        }
        .item {
            font-size: 27px !important;
            @media (max-width: 600px) {
                font-size: 25px !important;
            }
            @media (max-width: 500px) {
                font-size: 20px !important;
            }
        }
        &__input {
            margin-top: 140px;
            font-weight: 600;
            margin-bottom: 14px;
            height: 58px;
            font-size: 27px;
            @media (max-width: 600px) {
                font-size: 25px;
            }
            @media (max-width: 500px) {
                font-size: 20px;
            }
            @media (max-width: 420px) {
                font-size: 15px;
                height: 40px;
            }
        }
        &__num {
            width: 100%;
            height: 75px;
            @media (max-width: 750px) {
                height: 40px;
                width: 75%;
            }
        }
        .ui.button {
            background-color: #2d2727;
            color: $white;
            font-size: 30px;
            font-weight: 500;
            @media (max-width: 750px) {
                font-size: 15px;
            }
        }
        &__text {
            padding-top: 55px;
            font-size: 35px;
            font-weight: 600;
            @media (max-width: 850px) {
                font-size: 20px;
            }
        }
        &__password {
            max-width: 360px;
            height: 75px;
            font-size: 46px;
            @media (max-width: 400px) {
                width: 100%;
            }
        }
        &__grid {
            margin: 0 auto;
            width: 500px;
            @media (max-width: 750px) {
                width: 100%;
            }
        }
        .ui.grid > .row {
            display: revert;
        }
        &__button {
            font-size: 24px;
            margin-top: 22px;
            width: 100%;
            height: 72px;
        }
    }
}
