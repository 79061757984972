@import '../../styles/_variables';

.qa {
    background: $white;
    min-height: 100vh;
    &__header{
        background: $gradient-black;
        width: 100%;
        height: 62px;
        display: flex;
        justify-content: space-between;
    }
    &__exit{
        padding: 17px;
        font-size: 20px;
        color: $gray-light;
        font-weight: 600;
        &:hover{
            color: $gray;
            cursor: pointer;
        }
    }
    .task{
        .ui.action.input>.button, .ui.action.input>.buttons>.button{
            border-radius:  .28571429rem .28571429rem .28571429rem .28571429rem
        }
        .ui.action.input:not([class*="left action"])>input {
            border-radius: 0!important;
            border-right-color: transparent!important;
            border-left-color: transparent!important;
        }
        .element.style {
            border-radius:  0 .28571429rem .28571429rem 0
        }
        &__body{
            display: flex;
            justify-items: center;
            align-items: center;
            justify-content: space-between;
        }
        &__text{
            color: $dark;
            font-size: 25px;
        }
        &__subtitle{
            color: $gray-light;
            font-size: 25px;
        }
        &__button{
            font-size: 16px;
            width: 150px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-items: center;
            @media (max-width: 900px) {
                display: block;
                font-size: 14px;
                width: 100px;
            }
            @media (max-width: 600px) {
                width: 100px;
            }
            @media (max-width: 560px) {
                display: none;
            }
        }
        &__button-mibile{
            font-size: 12px;
            width: 100px;
            height: 30px;
            display: none;
            @media (max-width: 560px) {
                margin-top: 5px;
                display: flex;
                align-items: center;
                justify-items: center;
            }
        }
        &__number{
            width: 50%;
            font-size: 17px;
            font-weight: 700;
        }
        &__text{
            background-color: #F6FAFF;
            span{
                color: $dark;
                font-size: 18px;
                font-weight: 600;
                @media (max-width: 500px) {
                    font-size: 16px;
                }
            }
        }
        &__input{
            height: 45px;
            max-width: 90px;
        }
        &__title{
            height: 60px;
            margin: 25px;
            @media (max-width: 500px) {
                height: 80px;
            }
            span{
                display: none;
                color: $gray-dark;
                float: right;
                font-size: 20px;
                font-weight: 600;
            }
            p{
                color: $black;
                display: inline-block;
                font-size: 20px;
                font-weight: 500;
                padding: 0px;
                margin: 0px;
                border-right: 1px $black solid;
                padding-right: 15px;
                padding-left: 15px;
                &:last-child{
                    border-right: none;
                }
                @media (max-width: 1300px) {
                    font-size: 18px;
                }
                @media (max-width: 500px) {
                    font-size: 16px;
                }
            }
        }
    }
}
