@import "../../styles/_variables.scss";

.notice{
    position: fixed;
    bottom: 25px;
    margin-left: 15px;
    margin-top: 25px;
    &__body{
        border-radius: 10px;
        min-height: 70px;
        width: 240px;
        background: rgba(63, 224, 111, 0.66);
        text-align: center;
        border: #0d822d 2px solid;
    }
    &__title{
        color: #0d822d;
        padding-top: 20px;
        font-size: 16px;
        font-weight: 600;
    }
}